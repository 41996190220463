import * as React from 'react'
import DefaultLayout from '../layouts/index'

const NotFoundPage = () => (
  <DefaultLayout location={{ pathname: 'app' }}>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </DefaultLayout>
)

export default NotFoundPage
